<template>
  <div class="bt-forgot">

  </div>
</template>

<script>
export default {
  name: 'Forgot'
}
</script>
